.z-depth-1, .btn.btn-raised,
.btn-group.btn-raised, .btn.btn-floating,
.btn-group.btn-floating {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.z-depth-1-half, .btn.btn-raised:hover,
.btn-group.btn-raised:hover, .btn.btn-floating:hover,
.btn-group.btn-floating:hover, .pricing-table.featured, .pricing-table:hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.z-depth-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

.z-depth-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

.divider {
  display: block;
  border: 0;
  border-top: 1px solid transparent;
  margin-top: 10px;
  margin-bottom: 10px;
}

.divider.divider-solid {
  border-style: solid;
  border-color: #e2edeb;
}

.divider.divider-dashed {
  border-style: dashed;
  border-color: #e2edeb;
}

.divider.divider-dotted {
  border-style: dotted;
  border-color: #e2edeb;
}

.divider-xs {
  margin-top: 3px;
  margin-bottom: 3px;
}

.divider-sm {
  margin-top: 5px;
  margin-bottom: 5px;
}

.divider-md {
  margin-top: 15px;
  margin-bottom: 15px;
}

.divider-lg {
  margin-top: 20px;
  margin-bottom: 20px;
}

.divider-xl {
  margin-top: 30px;
  margin-bottom: 30px;
}

.divider-xxl {
  margin-top: 50px;
  margin-bottom: 50px;
}

.space {
  display: inline;
  padding: 6px;
}

.space-md {
  padding: 15px;
}

.space-lg {
  padding: 25px;
}

span.block {
  display: block;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-h {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-border {
  border: 0 !important;
}

.no-border-radius {
  border-radius: 0;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-h {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-shadow {
  box-shadow: 0;
}

.full-width {
  width: 100%;
}

.pull-in {
  margin-left: -15px;
  margin-right: -15px;
}

.margin-b-lg {
  margin-bottom: 30px;
}

body {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

body p,
html p {
  line-height: 1.42857;
}

::selection {
  background: #9C1919;
  color: #ffffff;
}

::-moz-selection {
  background: #9C1919;
  color: #ffffff;
}

:focus {
  outline: none;
}

a {
  text-decoration: none;
}

a:active, a:focus {
  text-decoration: none;
  outline: none;
}

a.bg-primary {
  background-color: #9C1919;
  color: #ffffff;
}

a.bg-primary:hover {
  background-color: #9C1919;
  color: #ffffff;
}

a.bg-success {
  background-color: #8BC34A;
  color: #ffffff;
}

a.bg-success:hover {
  background-color: #8BC34A;
  color: #ffffff;
}

a.bg-info {
  background-color: #00BCD4;
  color: #ffffff;
}

a.bg-info:hover {
  background-color: #00BCD4;
  color: #ffffff;
}

a.bg-warning {
  background-color: #FFCA28;
  color: #ffffff;
}

a.bg-warning:hover {
  background-color: #FFCA28;
  color: #ffffff;
}

a.bg-danger {
  background-color: #F44336;
  color: #ffffff;
}

a.bg-danger:hover {
  background-color: #F44336;
  color: #ffffff;
}

.bg-light {
  background-color: #fff;
  color: #3D4051;
}

.bg-light a {
  color: #3D4051;
}

.bg-light a:hover {
  color: #3D4051;
}

.bg-white {
  background-color: #fff;
  color: #3D4051;
}

.bg-white a {
  color: #3D4051;
}

.bg-white a:hover {
  color: #3D4051;
}

.bg-body {
  background-color: #f3f3f3;
  color: #3D4051;
}

.bg-body a {
  color: #3D4051;
}

.bg-body a:hover {
  color: #3D4051;
}

.bg-dark {
  background-color: #262B33;
  color: #ffffff;
}

.bg-dark a {
  color: #ffffff;
}

.bg-dark a:hover {
  color: #ffffff;
}

.bg-dark-light {
  background-color: #3D4051;
  color: #ffffff;
}

.bg-dark-light a {
  color: #ffffff;
}

.bg-dark-light a:hover {
  color: #ffffff;
}

.bg-black {
  background-color: #000;
  color: #ffffff;
}

.bg-black a {
  color: #ffffff;
}

.bg-black a:hover {
  color: #ffffff;
}

.bg-primary {
  background-color: #9C1919;
  color: #ffffff;
}

.bg-primary a {
  color: #ffffff;
}

.bg-primary a:hover {
  color: #ffffff;
}

.bg-primary-light {
  background-color: #5C6BC0;
  color: #ffffff;
}

.bg-primary-light a {
  color: #ffffff;
}

.bg-primary-light a:hover {
  color: #ffffff;
}

.bg-success {
  background-color: #8BC34A;
  color: #ffffff;
}

.bg-success a {
  color: #ffffff;
}

.bg-success a:hover {
  color: #ffffff;
}

.bg-info {
  background-color: #00BCD4;
  color: #ffffff;
}

.bg-info a {
  color: #ffffff;
}

.bg-info a:hover {
  color: #ffffff;
}

.bg-info-alt {
  background-color: #7E57C2;
  color: #ffffff;
}

.bg-info-alt a {
  color: #ffffff;
}

.bg-info-alt a:hover {
  color: #ffffff;
}

.bg-warning {
  background-color: #FFCA28;
  color: #ffffff;
}

.bg-warning a {
  color: #ffffff;
}

.bg-warning a:hover {
  color: #ffffff;
}

.bg-danger {
  background-color: #F44336;
  color: #ffffff;
}

.bg-danger a {
  color: #ffffff;
}

.bg-danger a:hover {
  color: #ffffff;
}

.bg-dark {
  background-color: #262B33;
  color: #ffffff;
}

.bg-dark a {
  color: #ffffff;
}

.bg-dark a:hover {
  color: #ffffff;
}

.bg-bright {
  background-color: #fafafa;
  color: #3D4051;
}

.bg-bright a {
  color: #3D4051;
}

.bg-bright a:hover {
  color: #3D4051;
}

.bg-white {
  background-color: #ffffff;
  color: #3D4051;
}

.bg-white a {
  color: #3D4051;
}

.bg-white a:hover {
  color: #3D4051;
}

.bg-facebook {
  background-color: #335397;
  color: #ffffff;
}

.bg-facebook a {
  color: #ffffff;
}

.bg-facebook a:hover {
  color: #ffffff;
}

.bg-twitter {
  background-color: #00c7f7;
  color: #ffffff;
}

.bg-twitter a {
  color: #ffffff;
}

.bg-twitter a:hover {
  color: #ffffff;
}

.bg-google-plus {
  background-color: #df4a32;
  color: #ffffff;
}

.bg-google-plus a {
  color: #ffffff;
}

.bg-google-plus a:hover {
  color: #ffffff;
}

.ui-section {
  margin-bottom: 60px;
}

.section-header,
.article-title {
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  margin: .6em 0;
  color: #9C1919;
}

.section-header.color-dark,
.article-title.color-dark {
  color: #3D4051;
}

.chapter .article {
  padding-bottom: 30px;
}

.chapter .article:nth-of-type(1) .article-title {
  margin: 0 0 30px;
  border-top: none;
}

.chapter .article.article-dark {
  background-color: rgba(0, 0, 0, 0.015);
}

.chapter .article.article-bordered {
  border-top: 1px solid rgba(0, 0, 0, 0.117647);
  border-bottom: 1px solid rgba(0, 0, 0, 0.117647);
}

.chapter .article.article-bordered:last-child {
  border-bottom: 0;
}

.chapter .article.padding-lg-v {
  padding: 75px 0;
}

.chapter .article-title {
  font-size: 24px;
  padding-top: 80px;
  font-weight: normal;
  line-height: 32px;
  margin: 48px 0 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.117647);
  color: #9C1919;
}

.hero {
  padding: 70px 0 75px;
  text-align: center;
}

.hero-title {
  -webkit-font-smoothing: antialiased;
}

.hero-title {
  color: #3D4051;
  font-size: 45px;
  font-weight: 300;
  line-height: 50px;
  margin-bottom: 10px;
}

.hero-tagline {
  margin: 10px auto 30px;
  max-width: 700px;
  color: #3D4051;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
}

.page-container {
  max-width: 1170px;
  margin: auto;
}

.blockquote-colored {
  border-left: 5px solid #9C1919;
}

.blockquote-colored.pull-right {
  border-right: 5px solid #9C1919;
  border-left: 0;
}

.gradient-text {
  font-size: 150px;
  font-weight: 300;
  color: #9C1919;
  background: -webkit-linear-gradient(92deg, #fb83fa, #00aced);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-small {
  font-size: 12px;
}

.text-normal {
  font-size: 14px;
}

.text-huge {
  font-size: 72px;
}

.text-large {
  font-size: 50px;
}

.size-h1 {
  font-size: 36px;
}

.size-h2 {
  font-size: 30px;
}

.size-h3 {
  font-size: 24px;
}

.size-h4 {
  font-size: 18px;
}

.text-thin {
  font-weight: 300;
}

.text-ultralight {
  font-weight: 100;
}

.color-primary,
a.color-primary {
  color: #9C1919;
}

.color-primary:hover,
a.color-primary:hover {
  color: #9C1919;
}

.color-success,
a.color-success {
  color: #8BC34A;
}

.color-success:hover,
a.color-success:hover {
  color: #8BC34A;
}

.color-info,
a.color-info {
  color: #00BCD4;
}

.color-info:hover,
a.color-info:hover {
  color: #00BCD4;
}

.color-info-alt,
a.color-info-alt {
  color: #7E57C2;
}

.color-info-alt:hover,
a.color-info-alt:hover {
  color: #7E57C2;
}

.color-warning,
a.color-warning {
  color: #FFCA28;
}

.color-warning:hover,
a.color-warning:hover {
  color: #FFCA28;
}

.color-danger,
a.color-danger {
  color: #F44336;
}

.color-danger:hover,
a.color-danger:hover {
  color: #F44336;
}

.color-dark,
a.color-dark {
  color: #262B33;
}

.color-dark:hover,
a.color-dark:hover {
  color: #262B33;
}

.color-reverse,
a.color-dark {
  color: #ffffff;
}

.color-reverse:hover,
a.color-dark:hover {
  color: #ffffff;
}

.color-gray-darker,
a.color-gray-darker {
  color: #222222;
}

.color-gray-darker:hover,
a.color-gray-darker:hover {
  color: #222222;
}

.color-gray-dark,
a.color-gray-dark {
  color: #333333;
}

.color-gray-dark:hover,
a.color-gray-dark:hover {
  color: #333333;
}

.color-gray,
a.color-gray {
  color: #555555;
}

.color-gray:hover,
a.color-gray:hover {
  color: #555555;
}

.color-gray-light,
a.color-gray-light {
  color: #777777;
}

.color-gray-light:hover,
a.color-gray-light:hover {
  color: #777777;
}

.color-gray-lighter,
a.color-gray-lighter {
  color: #eeeeee;
}

.color-gray-lighter:hover,
a.color-gray-lighter:hover {
  color: #eeeeee;
}

.dropcap,
.dropcap-square,
.dropcap-circle {
  display: block;
  float: left;
  font-weight: normal;
  line-height: 36px;
  margin-right: 6px;
  text-shadow: none;
}

.dropcap {
  font-size: 3.1em;
}

.dropcap-square,
.dropcap-circle {
  background-color: #eeeeee;
  color: #3D4051;
  width: 36px;
  text-align: center;
}

.dropcap-square {
  border-radius: 2px;
  font-size: 2.3em;
}

.dropcap-circle {
  border-radius: 50%;
  font-size: 1.78em;
}

.dropcap.colored {
  color: #9C1919;
}

.dropcap-square.colored,
.dropcap-circle.colored {
  background-color: #9C1919;
  color: #ffffff;
}

.ui-highlight {
  background-color: #262B33;
  color: #ffffff;
  border-radius: 2px;
  padding: 2px 5px;
}

.ui-highlight.colored {
  background-color: #9C1919;
}

.hr-colored {
  border-color: #9C1919;
}

@media only screen and (max-width: 800px) {
  .table-flip-scroll {
    /* sort out borders */
  }
  .table-flip-scroll .cf:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  .table-flip-scroll * html .cf {
    zoom: 1;
  }
  .table-flip-scroll *:first-child + html .cf {
    zoom: 1;
  }
  .table-flip-scroll table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  .table-flip-scroll th,
  .table-flip-scroll td {
    margin: 0;
    vertical-align: top;
  }
  .table-flip-scroll th {
    text-align: left;
  }
  .table-flip-scroll table {
    display: block;
    position: relative;
    width: 100%;
  }
  .table-flip-scroll thead {
    display: block;
    float: left;
  }
  .table-flip-scroll tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }
  .table-flip-scroll thead tr {
    display: block;
  }
  .table-flip-scroll .table > thead > tr > th:first-child {
    border-top: 1px solid #ddd;
  }
  .table-flip-scroll th {
    display: block;
    text-align: right;
  }
  .table-flip-scroll tbody tr {
    display: inline-block;
    vertical-align: top;
  }
  .table-flip-scroll td {
    display: block;
    min-height: 1.25em;
    text-align: left;
  }
  .table-flip-scroll th {
    border-bottom: 0;
    border-left: 0;
  }
  .table-flip-scroll td {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
  .table-flip-scroll tbody tr {
    border-left: 1px solid #babcbf;
  }
  .table-flip-scroll th:last-child,
  .table-flip-scroll td:last-child {
    border-bottom: 1px solid #babcbf;
  }
}

.ui-radio {
  position: relative;
  margin: 0 20px 10px 20px;
  font-size: 14px;
  line-height: 20px;
  height: 20px;
}

.ui-radio input[type="radio"] + span:hover {
  cursor: pointer;
}

.ui-radio input[type="radio"] + span:hover:before {
  border-color: #9C1919;
}

.ui-radio input[type="radio"]:disabled + span:hover {
  cursor: not-allowed;
}

.ui-radio input[type="radio"]:disabled + span:hover:before {
  border-color: #ccc;
}

.ui-radio input[type="radio"] {
  display: none;
}

.ui-radio input[type="radio"] + span {
  padding-left: 10px;
  font-weight: normal;
}

.ui-radio input[type="radio"] + span:before {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: -20px;
  top: 0;
  background: white;
  border-radius: 50%;
  border: 2px solid #ccc;
  transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.ui-radio input[type="radio"] + span:after {
  visibility: hidden;
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  left: -15px;
  background-color: #9C1919;
  border-radius: 50%;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.ui-radio input[type="radio"]:disabled + span:before {
  opacity: .65;
  border: 2px solid #ccc;
  cursor: no-drop;
}

.ui-radio input[type="radio"]:checked + span:before {
  border-color: #9C1919;
}

.ui-radio input[type="radio"]:checked + span:after {
  visibility: visible;
  transform: scale(1);
}

.ui-radio input[type="radio"]:disabled:checked + span:before {
  border-color: #ccc;
}

.ui-radio input[type="radio"]:disabled:checked + span:after {
  opacity: .65;
  cursor: no-drop;
  background-color: #ccc;
}

.ui-checkbox {
  position: relative;
  margin: 0 20px 10px 20px;
  line-height: 20px;
  height: 20px;
}

.ui-checkbox input[type="checkbox"] + span:hover {
  cursor: pointer;
}

.ui-checkbox input[type="checkbox"] + span:hover:before {
  border-color: #9C1919;
}

.ui-checkbox input[type="checkbox"]:disabled + span:hover {
  cursor: not-allowed;
}

.ui-checkbox input[type="checkbox"]:disabled + span:hover:before {
  border-color: #ccc;
}

.ui-checkbox input[type="checkbox"] {
  display: none;
}

.ui-checkbox input[type="checkbox"] + span {
  font-weight: normal;
}

.ui-checkbox input[type="checkbox"] + span:before {
  content: "";
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-left: -20px;
  background-color: transparent;
  border-radius: 2px;
  border: 2px solid #ccc;
  transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.ui-checkbox input[type="checkbox"]:disabled + span:before {
  opacity: .65;
  border: 2px solid #ccc;
  cursor: no-drop;
}

.ui-checkbox input[type="checkbox"]:checked + span:before {
  border-color: #9C1919;
}

.ui-checkbox input[type="checkbox"] + span:after {
  visibility: hidden;
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: 7px;
  left: -15px;
  background-color: #9C1919;
  transform: scale(0);
  transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.ui-checkbox input[type="checkbox"]:checked + span:after {
  visibility: visible;
  transform: scale(1);
}

.ui-checkbox input[type="checkbox"]:disabled:checked + span:before {
  opacity: .65;
  border: 2px solid #ccc;
}

.ui-checkbox input[type="checkbox"]:disabled:checked + span:after {
  opacity: .65;
  cursor: no-drop;
  background-color: #ccc;
}

/*
@license textAngular
Author : Austin Anderson
License : 2013 MIT
Version 1.3.0

See README.md or https://github.com/fraywing/textAngular/wiki for requirements and use.
*/
body > input.ta-hidden-input {
  display: none;
}

/* add generic styling for the editor */
.ui-editor {
  /* add the styling for the awesomness of the resizer */
  /* copy the popover code from bootstrap so this will work even without it */
}

.ui-editor .btn-group {
  margin-bottom: 5px;
}

.ui-editor .ta-toolbar {
  margin: 0 0 10px;
}

.ui-editor.ta-root.focussed > .ta-scroll-window.form-control {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.ui-editor .ta-editor.ta-html, .ui-editor .ta-scroll-window.form-control {
  min-height: 300px;
  height: auto;
  overflow: auto;
  font-family: inherit;
  font-size: 100%;
}

.ui-editor .ta-scroll-window.form-control {
  position: relative;
  padding: 0;
}

.ui-editor .ta-scroll-window > .ta-bind {
  height: auto;
  min-height: 300px;
  padding: 6px 12px;
}

.ui-editor .ta-editor:focus {
  user-select: text;
}

.ui-editor .ta-resizer-handle-overlay {
  z-index: 100;
  position: absolute;
  display: none;
}

.ui-editor .ta-resizer-handle-overlay > .ta-resizer-handle-info {
  position: absolute;
  bottom: 16px;
  right: 16px;
  border: 1px solid black;
  background-color: #FFF;
  padding: 0 4px;
  opacity: 0.7;
}

.ui-editor .ta-resizer-handle-overlay > .ta-resizer-handle-background {
  position: absolute;
  bottom: 5px;
  right: 5px;
  left: 5px;
  top: 5px;
  border: 1px solid black;
  background-color: rgba(0, 0, 0, 0.2);
}

.ui-editor .ta-resizer-handle-overlay > .ta-resizer-handle-corner {
  width: 10px;
  height: 10px;
  position: absolute;
}

.ui-editor .ta-resizer-handle-overlay > .ta-resizer-handle-corner-tl {
  top: 0;
  left: 0;
  border-left: 1px solid black;
  border-top: 1px solid black;
}

.ui-editor .ta-resizer-handle-overlay > .ta-resizer-handle-corner-tr {
  top: 0;
  right: 0;
  border-right: 1px solid black;
  border-top: 1px solid black;
}

.ui-editor .ta-resizer-handle-overlay > .ta-resizer-handle-corner-bl {
  bottom: 0;
  left: 0;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}

.ui-editor .ta-resizer-handle-overlay > .ta-resizer-handle-corner-br {
  bottom: 0;
  right: 0;
  border: 1px solid black;
  cursor: se-resize;
  background-color: white;
}

.ui-editor .popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.ui-editor .popover.top {
  margin-top: -10px;
}

.ui-editor .popover.bottom {
  margin-top: 10px;
}

.ui-editor .popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.ui-editor .popover-content {
  padding: 9px 14px;
}

.ui-editor .popover > .arrow,
.ui-editor .popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.ui-editor .popover > .arrow {
  border-width: 11px;
}

.ui-editor .popover > .arrow:after {
  content: "";
  border-width: 10px;
}

.ui-editor .popover.top > .arrow {
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, 0.25);
  border-bottom-width: 0;
}

.ui-editor .popover.top > .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  content: " ";
  border-top-color: #fff;
  border-bottom-width: 0;
}

.ui-editor .popover.bottom > .arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.ui-editor .popover.bottom > .arrow:after {
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
}

.ui-wizard-form .steps .done > a {
  box-shadow: none;
}

md-input-container label {
  margin: 0;
}

.form-control {
  border-width: 1px;
  box-shadow: none;
}

.form-control:focus {
  border-color: #9C1919;
  box-shadow: none;
}

.form-group .col-sm-10 {
  position: relative;
}

.form-group .col-sm-10 .icon {
  position: absolute;
  right: 25px;
  top: 10px;
}

.input-round {
  border-radius: 25px;
}

input.input-primary {
  border-color: #9C1919;
}

input.input-primary:focus {
  border-color: #9C1919;
}

input.input-info {
  border-color: #00BCD4;
}

input.input-info:focus {
  border-color: #00BCD4;
}

input.input-success {
  border-color: #8BC34A;
}

input.input-success:focus {
  border-color: #8BC34A;
}

input.input-warning {
  border-color: #FFCA28;
}

input.input-warning:focus {
  border-color: #FFCA28;
}

input.input-danger {
  border-color: #F44336;
}

input.input-danger:focus {
  border-color: #F44336;
}

.ui-select {
  position: relative;
  display: inline-block;
  margin: 0 0 2px 1.2%;
  width: auto;
  height: auto;
  border: 1px solid #CBD5DD;
  border-radius: 2px;
}

.ui-select > select {
  z-index: 99;
  display: block;
  position: relative;
  padding: 10px 15px 10px 10px;
  min-width: 200px;
  width: 120%;
  border: none;
  outline: none;
  background: transparent;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  text-indent: 0.01px;
  text-overflow: '';
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.ui-select select::-ms-expand {
  display: none;
}

.ui-select:after {
  z-index: 0;
  content: "";
  position: absolute;
  right: 8%;
  top: 50%;
  color: #CBD5DD;
  width: 0;
  margin-top: -3px;
  height: 0;
  border-top: 6px solid;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  pointer-events: none;
}

/* Show only the native arrow */
@-moz-document url-prefix() {
  .ui-select {
    overflow: hidden;
  }
  .ui-select select {
    width: 120%;
    width: -moz-calc(100% + 3em);
    width: calc(100% + em);
  }
}

/* Firefox focus has odd artifacts around the text, this kills that. See https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-focusring */
.ui-select select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.form-validation .ng-valid.ng-dirty {
  border-color: #8BC34A;
}

.form-validation .ng-invalid.ng-dirty {
  border-color: #F44336;
}

.form-validation md-input-container > md-icon {
  font-size: 20px;
  line-height: 30px;
  color: #3D4051;
}

.form-validation md-input-container:not(.md-input-invalid).md-input-has-value > md-icon {
  color: #8BC34A;
}

.form-validation md-input-container.md-input-invalid > md-icon {
  color: #F44336;
}

.btn-info-alt {
  color: #fff;
  background-color: #7E57C2;
  border-color: #7E57C2;
}

.btn-info-alt:focus, .btn-info-alt.focus {
  color: #fff;
  background-color: #643da9;
  border-color: #432971;
}

.btn-info-alt:hover {
  color: #fff;
  background-color: #643da9;
  border-color: #603ba1;
}

.btn-info-alt:active, .btn-info-alt.active,
.open > .btn-info-alt.dropdown-toggle {
  color: #fff;
  background-color: #643da9;
  border-color: #603ba1;
}

.btn-info-alt:active:hover, .btn-info-alt:active:focus, .btn-info-alt:active.focus, .btn-info-alt.active:hover, .btn-info-alt.active:focus, .btn-info-alt.active.focus,
.open > .btn-info-alt.dropdown-toggle:hover,
.open > .btn-info-alt.dropdown-toggle:focus,
.open > .btn-info-alt.dropdown-toggle.focus {
  color: #fff;
  background-color: #55348f;
  border-color: #432971;
}

.btn-info-alt:active, .btn-info-alt.active,
.open > .btn-info-alt.dropdown-toggle {
  background-image: none;
}

.btn-info-alt.disabled:hover, .btn-info-alt.disabled:focus, .btn-info-alt.disabled.focus, .btn-info-alt[disabled]:hover, .btn-info-alt[disabled]:focus, .btn-info-alt[disabled].focus,
fieldset[disabled] .btn-info-alt:hover,
fieldset[disabled] .btn-info-alt:focus,
fieldset[disabled] .btn-info-alt.focus {
  background-color: #7E57C2;
  border-color: #7E57C2;
}

.btn-info-alt .badge {
  color: #7E57C2;
  background-color: #fff;
}

.btn-dark {
  color: #fff;
  background-color: #262B33;
  border-color: #262B33;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #101216;
  border-color: black;
}

.btn-dark:hover {
  color: #fff;
  background-color: #101216;
  border-color: #0c0d10;
}

.btn-dark:active, .btn-dark.active,
.open > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #101216;
  border-color: #0c0d10;
}

.btn-dark:active:hover, .btn-dark:active:focus, .btn-dark:active.focus, .btn-dark.active:hover, .btn-dark.active:focus, .btn-dark.active.focus,
.open > .btn-dark.dropdown-toggle:hover,
.open > .btn-dark.dropdown-toggle:focus,
.open > .btn-dark.dropdown-toggle.focus {
  color: #fff;
  background-color: #010101;
  border-color: black;
}

.btn-dark:active, .btn-dark.active,
.open > .btn-dark.dropdown-toggle {
  background-image: none;
}

.btn-dark.disabled:hover, .btn-dark.disabled:focus, .btn-dark.disabled.focus, .btn-dark[disabled]:hover, .btn-dark[disabled]:focus, .btn-dark[disabled].focus,
fieldset[disabled] .btn-dark:hover,
fieldset[disabled] .btn-dark:focus,
fieldset[disabled] .btn-dark.focus {
  background-color: #262B33;
  border-color: #262B33;
}

.btn-dark .badge {
  color: #262B33;
  background-color: #fff;
}

.btn-line-default {
  color: #3D4051;
  background-color: transparent;
  border-color: #fafafa;
}

.btn-line-default:hover, .btn-line-default:focus, .btn-line-default:active, .btn-line-default.active {
  color: #3D4051;
  background-color: #fafafa;
}

.open .btn-line-default.dropdown-toggle {
  color: #3D4051;
  background-color: #fafafa;
}

.btn-line-primary {
  color: #3D4051;
  background-color: transparent;
  border-color: #9C1919;
}

.btn-line-primary:hover, .btn-line-primary:focus, .btn-line-primary:active, .btn-line-primary.active {
  color: #fff;
  background-color: #9C1919;
}

.open .btn-line-primary.dropdown-toggle {
  color: #fff;
  background-color: #9C1919;
}

.btn-line-success {
  color: #3D4051;
  background-color: transparent;
  border-color: #8BC34A;
}

.btn-line-success:hover, .btn-line-success:focus, .btn-line-success:active, .btn-line-success.active {
  color: #fff;
  background-color: #8BC34A;
}

.open .btn-line-success.dropdown-toggle {
  color: #fff;
  background-color: #8BC34A;
}

.btn-line-info {
  color: #3D4051;
  background-color: transparent;
  border-color: #00BCD4;
}

.btn-line-info:hover, .btn-line-info:focus, .btn-line-info:active, .btn-line-info.active {
  color: #fff;
  background-color: #00BCD4;
}

.open .btn-line-info.dropdown-toggle {
  color: #fff;
  background-color: #00BCD4;
}

.btn-line-warning {
  color: #3D4051;
  background-color: transparent;
  border-color: #FFCA28;
}

.btn-line-warning:hover, .btn-line-warning:focus, .btn-line-warning:active, .btn-line-warning.active {
  color: #fff;
  background-color: #FFCA28;
}

.open .btn-line-warning.dropdown-toggle {
  color: #fff;
  background-color: #FFCA28;
}

.btn-line-danger {
  color: #3D4051;
  background-color: transparent;
  border-color: #F44336;
}

.btn-line-danger:hover, .btn-line-danger:focus, .btn-line-danger:active, .btn-line-danger.active {
  color: #fff;
  background-color: #F44336;
}

.open .btn-line-danger.dropdown-toggle {
  color: #fff;
  background-color: #F44336;
}

.btn-line-dark {
  color: #3D4051;
  background-color: transparent;
  border-color: #262B33;
}

.btn-line-dark:hover, .btn-line-dark:focus, .btn-line-dark:active, .btn-line-dark.active {
  color: #fff;
  background-color: #262B33;
}

.open .btn-line-dark.dropdown-toggle {
  color: #fff;
  background-color: #262B33;
}

.btn-w-xs {
  min-width: 80px;
}

.btn-w-sm {
  min-width: 100px;
}

.btn-w-md {
  min-width: 135px;
}

.btn-w-lg {
  min-width: 160px;
}

.btn-round {
  border-radius: 2em;
}

.btn-gap {
  margin: 5px;
}

.btn-gap-h {
  margin: 0 5px;
}

.btn-gap-v {
  margin: 0 0 5px;
}

.btn.btn-raised,
.btn-group.btn-raised {
  text-transform: uppercase;
  -webkit-transition: 0.25s ease-out;
  -o-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}

.btn.btn-raised:hover, .btn.btn-raised:focus, .btn.btn-raised:active,
.btn-group.btn-raised:hover,
.btn-group.btn-raised:focus,
.btn-group.btn-raised:active {
  outline: none;
}

.btn.btn-raised:disabled, .btn.btn-raised.disabled,
.btn-group.btn-raised:disabled,
.btn-group.btn-raised.disabled {
  box-shadow: none;
}

.btn.btn-floating,
.btn-group.btn-floating {
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
}

.btn.btn-floating:hover, .btn.btn-floating:focus, .btn.btn-floating:active,
.btn-group.btn-floating:hover,
.btn-group.btn-floating:focus,
.btn-group.btn-floating:active {
  outline: none;
}

.btn.btn-floating:disabled, .btn.btn-floating.disabled,
.btn-group.btn-floating:disabled,
.btn-group.btn-floating.disabled {
  box-shadow: none;
}

.btn.btn-raised.btn-primary:hover, .btn.btn-raised.btn-primary:focus, .btn.btn-raised.btn-primary.focus, .btn.btn-raised.btn-primary:active, .btn.btn-raised.btn-primary.active,
.open > .btn.btn-raised.btn-primary.dropdown-toggle, .btn.btn-floating.btn-primary:hover, .btn.btn-floating.btn-primary:focus, .btn.btn-floating.btn-primary.focus, .btn.btn-floating.btn-primary:active, .btn.btn-floating.btn-primary.active,
.open > .btn.btn-floating.btn-primary.dropdown-toggle,
.btn-group.btn-raised.btn-primary:hover,
.btn-group.btn-raised.btn-primary:focus,
.btn-group.btn-raised.btn-primary.focus,
.btn-group.btn-raised.btn-primary:active,
.btn-group.btn-raised.btn-primary.active,
.open >
.btn-group.btn-raised.btn-primary.dropdown-toggle,
.btn-group.btn-floating.btn-primary:hover,
.btn-group.btn-floating.btn-primary:focus,
.btn-group.btn-floating.btn-primary.focus,
.btn-group.btn-floating.btn-primary:active,
.btn-group.btn-floating.btn-primary.active,
.open >
.btn-group.btn-floating.btn-primary.dropdown-toggle {
  background-color: #861515;
  border-color: #861515;
}

.btn.btn-raised.btn-info:hover, .btn.btn-raised.btn-info:focus, .btn.btn-raised.btn-info.focus, .btn.btn-raised.btn-info:active, .btn.btn-raised.btn-info.active,
.open > .btn.btn-raised.btn-info.dropdown-toggle, .btn.btn-floating.btn-info:hover, .btn.btn-floating.btn-info:focus, .btn.btn-floating.btn-info.focus, .btn.btn-floating.btn-info:active, .btn.btn-floating.btn-info.active,
.open > .btn.btn-floating.btn-info.dropdown-toggle,
.btn-group.btn-raised.btn-info:hover,
.btn-group.btn-raised.btn-info:focus,
.btn-group.btn-raised.btn-info.focus,
.btn-group.btn-raised.btn-info:active,
.btn-group.btn-raised.btn-info.active,
.open >
.btn-group.btn-raised.btn-info.dropdown-toggle,
.btn-group.btn-floating.btn-info:hover,
.btn-group.btn-floating.btn-info:focus,
.btn-group.btn-floating.btn-info.focus,
.btn-group.btn-floating.btn-info:active,
.btn-group.btn-floating.btn-info.active,
.open >
.btn-group.btn-floating.btn-info.dropdown-toggle {
  background-color: #00a5bb;
  border-color: #00a5bb;
}

.btn.btn-raised.btn-warning:hover, .btn.btn-raised.btn-warning:focus, .btn.btn-raised.btn-warning.focus, .btn.btn-raised.btn-warning:active, .btn.btn-raised.btn-warning.active,
.open > .btn.btn-raised.btn-warning.dropdown-toggle, .btn.btn-floating.btn-warning:hover, .btn.btn-floating.btn-warning:focus, .btn.btn-floating.btn-warning.focus, .btn.btn-floating.btn-warning:active, .btn.btn-floating.btn-warning.active,
.open > .btn.btn-floating.btn-warning.dropdown-toggle,
.btn-group.btn-raised.btn-warning:hover,
.btn-group.btn-raised.btn-warning:focus,
.btn-group.btn-raised.btn-warning.focus,
.btn-group.btn-raised.btn-warning:active,
.btn-group.btn-raised.btn-warning.active,
.open >
.btn-group.btn-raised.btn-warning.dropdown-toggle,
.btn-group.btn-floating.btn-warning:hover,
.btn-group.btn-floating.btn-warning:focus,
.btn-group.btn-floating.btn-warning.focus,
.btn-group.btn-floating.btn-warning:active,
.btn-group.btn-floating.btn-warning.active,
.open >
.btn-group.btn-floating.btn-warning.dropdown-toggle {
  background-color: #ffc40f;
  border-color: #ffc40f;
}

.btn.btn-raised.btn-danger:hover, .btn.btn-raised.btn-danger:focus, .btn.btn-raised.btn-danger.focus, .btn.btn-raised.btn-danger:active, .btn.btn-raised.btn-danger.active,
.open > .btn.btn-raised.btn-danger.dropdown-toggle, .btn.btn-floating.btn-danger:hover, .btn.btn-floating.btn-danger:focus, .btn.btn-floating.btn-danger.focus, .btn.btn-floating.btn-danger:active, .btn.btn-floating.btn-danger.active,
.open > .btn.btn-floating.btn-danger.dropdown-toggle,
.btn-group.btn-raised.btn-danger:hover,
.btn-group.btn-raised.btn-danger:focus,
.btn-group.btn-raised.btn-danger.focus,
.btn-group.btn-raised.btn-danger:active,
.btn-group.btn-raised.btn-danger.active,
.open >
.btn-group.btn-raised.btn-danger.dropdown-toggle,
.btn-group.btn-floating.btn-danger:hover,
.btn-group.btn-floating.btn-danger:focus,
.btn-group.btn-floating.btn-danger.focus,
.btn-group.btn-floating.btn-danger:active,
.btn-group.btn-floating.btn-danger.active,
.open >
.btn-group.btn-floating.btn-danger.dropdown-toggle {
  background-color: #f32c1e;
  border-color: #f32c1e;
}

.btn.btn-raised.btn-default:hover, .btn.btn-raised.btn-default:focus, .btn.btn-raised.btn-default.focus, .btn.btn-raised.btn-default:active, .btn.btn-raised.btn-default.active,
.open > .btn.btn-raised.btn-default.dropdown-toggle, .btn.btn-floating.btn-default:hover, .btn.btn-floating.btn-default:focus, .btn.btn-floating.btn-default.focus, .btn.btn-floating.btn-default:active, .btn.btn-floating.btn-default.active,
.open > .btn.btn-floating.btn-default.dropdown-toggle,
.btn-group.btn-raised.btn-default:hover,
.btn-group.btn-raised.btn-default:focus,
.btn-group.btn-raised.btn-default.focus,
.btn-group.btn-raised.btn-default:active,
.btn-group.btn-raised.btn-default.active,
.open >
.btn-group.btn-raised.btn-default.dropdown-toggle,
.btn-group.btn-floating.btn-default:hover,
.btn-group.btn-floating.btn-default:focus,
.btn-group.btn-floating.btn-default.focus,
.btn-group.btn-floating.btn-default:active,
.btn-group.btn-floating.btn-default.active,
.open >
.btn-group.btn-floating.btn-default.dropdown-toggle {
  background-color: #ededed;
  border-color: #ededed;
}

.btn-flat {
  background: none;
  color: #3D4051;
  border: 1px solid transparent;
  text-transform: uppercase;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: 0.25s ease-out;
  -o-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}

.btn-flat:hover, .btn-flat:focus, .btn-flat:active {
  background-color: #fafafa;
  outline: none;
  box-shadow: none;
  border-color: #fafafa;
}

.btn-flat.btn-primary {
  color: #9C1919;
}

.btn-flat.btn-info {
  color: #00BCD4;
}

.btn-flat.btn-warning {
  color: #FFCA28;
}

.btn-flat.btn-danger {
  color: #F44336;
}

.btn-icon {
  padding: 0;
  display: inline-block;
  text-align: center;
  border-radius: 2px;
  height: 35px;
  width: 35px;
  line-height: 35px;
}

.btn-icon i {
  line-height: 35px;
}

.btn-icon.btn-icon-lined {
  line-height: 31px;
}

.btn-icon.btn-icon-lined i {
  line-height: 31px;
}

.btn-icon.btn-icon-lined.btn-icon-thin {
  line-height: 33px;
}

.btn-icon.btn-icon-lined.btn-icon-thin i {
  line-height: 33px;
}

.btn-icon-lined {
  display: inline-block;
  text-align: center;
  border-radius: 2px;
  background-color: #ffffff;
  border-width: 2px;
  border-style: solid;
  border-color: #3D4051;
  color: #3D4051;
  height: 35px;
  width: 35px;
  line-height: 35px;
}

.btn-icon-lined i {
  line-height: 35px;
}

.btn-icon-lined.btn-icon-lined {
  line-height: 31px;
}

.btn-icon-lined.btn-icon-lined i {
  line-height: 31px;
}

.btn-icon-lined.btn-icon-lined.btn-icon-thin {
  line-height: 33px;
}

.btn-icon-lined.btn-icon-lined.btn-icon-thin i {
  line-height: 33px;
}

.btn-icon-lined.btn-icon-thin {
  border-width: 1px;
}

.btn-icon-lined:hover {
  background-color: #ffffff;
  color: #ffffff;
}

.btn-icon-lined.btn-default-light {
  color: #bbb;
  border: 2px solid #bbb;
}

.btn-icon-lined.btn-default-light:hover {
  color: #bbb;
  border: 2px solid #bbb;
}

.btn-icon-lined.btn-default {
  color: #777777;
  border-color: #777777;
}

.btn-icon-lined.btn-default:hover {
  color: #777777;
  border-color: #777777;
}

.btn-icon-lined.btn-primary {
  color: #9C1919;
  border-color: #9C1919;
}

.btn-icon-lined.btn-success {
  color: #8BC34A;
  border-color: #8BC34A;
}

.btn-icon-lined.btn-info {
  color: #00BCD4;
  border-color: #00BCD4;
}

.btn-icon-lined.btn-warning {
  color: #FFCA28;
  border-color: #FFCA28;
}

.btn-icon-lined.btn-danger {
  color: #F44336;
  border-color: #F44336;
}

.btn-icon-round {
  border-radius: 50%;
}

.btn-icon-sm {
  height: 30px;
  width: 30px;
  line-height: 30px;
}

.btn-icon-sm i {
  line-height: 30px;
}

.btn-icon-sm.btn-icon-lined {
  line-height: 26px;
}

.btn-icon-sm.btn-icon-lined i {
  line-height: 26px;
}

.btn-icon-sm.btn-icon-lined.btn-icon-thin {
  line-height: 28px;
}

.btn-icon-sm.btn-icon-lined.btn-icon-thin i {
  line-height: 28px;
}

.btn-icon-md {
  height: 45px;
  width: 45px;
  line-height: 45px;
  font-size: 18px;
}

.btn-icon-md i {
  line-height: 45px;
}

.btn-icon-md.btn-icon-lined {
  line-height: 41px;
}

.btn-icon-md.btn-icon-lined i {
  line-height: 41px;
}

.btn-icon-md.btn-icon-lined.btn-icon-thin {
  line-height: 43px;
}

.btn-icon-md.btn-icon-lined.btn-icon-thin i {
  line-height: 43px;
}

.btn-icon-lg {
  height: 65px;
  width: 65px;
  line-height: 65px;
  font-size: 28px;
}

.btn-icon-lg i {
  line-height: 65px;
}

.btn-icon-lg.btn-icon-lined {
  line-height: 61px;
}

.btn-icon-lg.btn-icon-lined i {
  line-height: 61px;
}

.btn-icon-lg.btn-icon-lined.btn-icon-thin {
  line-height: 63px;
}

.btn-icon-lg.btn-icon-lined.btn-icon-thin i {
  line-height: 63px;
}

.btn-icon-lg-alt {
  height: 70px;
  width: 70px;
  line-height: 70px;
}

.btn-icon-lg-alt i {
  line-height: 70px;
}

.btn-icon-lg-alt.btn-icon-lined {
  line-height: 66px;
}

.btn-icon-lg-alt.btn-icon-lined i {
  line-height: 66px;
}

.btn-icon-lg-alt.btn-icon-lined.btn-icon-thin {
  line-height: 68px;
}

.btn-icon-lg-alt.btn-icon-lined.btn-icon-thin i {
  line-height: 68px;
}

.btn-icon-xl {
  height: 80px;
  width: 80px;
  line-height: 80px;
}

.btn-icon-xl i {
  line-height: 80px;
}

.btn-icon-xl.btn-icon-lined {
  line-height: 76px;
}

.btn-icon-xl.btn-icon-lined i {
  line-height: 76px;
}

.btn-icon-xl.btn-icon-lined.btn-icon-thin {
  line-height: 78px;
}

.btn-icon-xl.btn-icon-lined.btn-icon-thin i {
  line-height: 78px;
}

.btn-twitter {
  color: #ffffff;
  background-color: #00c7f7;
  border-color: #00c7f7;
}

.btn-twitter:hover, .btn-twitter:focus, .btn-twitter:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #00a6ce;
  border-color: #0096ba;
}

.btn-facebook {
  color: #ffffff;
  background-color: #335397;
  border-color: #335397;
}

.btn-facebook:hover, .btn-facebook:focus, .btn-facebook:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #294279;
  border-color: #243a69;
}

.btn-gplus,
.btn-google-plus {
  color: #ffffff;
  background-color: #dd4a38;
  border-color: #dd4a38;
}

.btn-gplus:hover, .btn-gplus:focus, .btn-gplus:active,
.btn-google-plus:hover,
.btn-google-plus:focus,
.btn-google-plus:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #ca3522;
  border-color: #b8301f;
}

.btn-instagram {
  color: #ffffff;
  background-color: #82685A;
  border-color: #82685A;
}

.btn-instagram:hover, .btn-instagram:focus, .btn-instagram:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #6a5549;
  border-color: #5e4b41;
}

.btn-vimeo {
  color: #ffffff;
  background-color: #63879C;
  border-color: #63879C;
}

.btn-vimeo:hover, .btn-vimeo:focus, .btn-vimeo:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #537183;
  border-color: #4b6777;
}

.btn-flickr {
  color: #ffffff;
  background-color: #0061DB;
  border-color: #0061DB;
}

.btn-flickr:hover, .btn-flickr:focus, .btn-flickr:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #004fb2;
  border-color: #00469e;
}

.btn-github {
  color: #ffffff;
  background-color: #3B3B3B;
  border-color: #3B3B3B;
}

.btn-github:hover, .btn-github:focus, .btn-github:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #272727;
  border-color: #1c1c1c;
}

.btn-pinterest {
  color: #ffffff;
  background-color: #D73532;
  border-color: #D73532;
}

.btn-pinterest:hover, .btn-pinterest:focus, .btn-pinterest:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #bc2725;
  border-color: #ab2421;
}

.btn-tumblr {
  color: #ffffff;
  background-color: #586980;
  border-color: #586980;
}

.btn-tumblr:hover, .btn-tumblr:focus, .btn-tumblr:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #475568;
  border-color: #3f4b5c;
}

.btn-linkedin {
  color: #ffffff;
  background-color: #018FAF;
  border-color: #018FAF;
}

.btn-linkedin:hover, .btn-linkedin:focus, .btn-linkedin:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #016e86;
  border-color: #015d72;
}

.btn-dribbble {
  color: #ffffff;
  background-color: #EA73A0;
  border-color: #EA73A0;
}

.btn-dribbble:hover, .btn-dribbble:focus, .btn-dribbble:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #e55088;
  border-color: #e23e7c;
}

.btn-stumbleupon {
  color: #ffffff;
  background-color: #EA4B24;
  border-color: #EA4B24;
}

.btn-stumbleupon:hover, .btn-stumbleupon:focus, .btn-stumbleupon:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #d13914;
  border-color: #bf3412;
}

.btn-lastfm {
  color: #ffffff;
  background-color: #B80638;
  border-color: #B80638;
}

.btn-lastfm:hover, .btn-lastfm:focus, .btn-lastfm:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #90052c;
  border-color: #7d0426;
}

.btn-evernote {
  color: #ffffff;
  background-color: #3BAB27;
  border-color: #3BAB27;
}

.btn-evernote:hover, .btn-evernote:focus, .btn-evernote:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #308a1f;
  border-color: #2a791c;
}

.btn-skype {
  color: #ffffff;
  background-color: #00B0F6;
  border-color: #00B0F6;
}

.btn-skype:hover, .btn-skype:focus, .btn-skype:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #0093cd;
  border-color: #0084b9;
}

.btn-soundcloud {
  color: #ffffff;
  background-color: #0066FF;
  border-color: #0066FF;
}

.btn-soundcloud:hover, .btn-soundcloud:focus, .btn-soundcloud:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #0056d6;
  border-color: #004ec2;
}

.btn-behance {
  color: #ffffff;
  background-color: #B80638;
  border-color: #B80638;
}

.btn-behance:hover, .btn-behance:focus, .btn-behance:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #90052c;
  border-color: #7d0426;
}

.btn-rss {
  color: #ffffff;
  background-color: #F79638;
  border-color: #F79638;
}

.btn-rss:hover, .btn-rss:focus, .btn-rss:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #f58111;
  border-color: #e87709;
}

.btn-youtube {
  color: #ffffff;
  background-color: #CC181E;
  border-color: #CC181E;
}

.btn-youtube:hover, .btn-youtube:focus, .btn-youtube:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #a71419;
  border-color: #951216;
}

.ui-wave {
  position: relative;
  overflow: hidden;
  z-index: 0;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.ui-wave.ui-wave-overflowed {
  overflow: visible;
}

.ui-wave .ink {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  transform: scale(0);
}

.ui-wave.btn-flat .ink, .ui-wave.btn-default .ink {
  background: rgba(0, 0, 0, 0.2);
}

.wave-animate {
  animation: ripple 0.65s linear;
}

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

.nav-container .ui-wave .ink {
  background: rgba(0, 0, 0, 0.2);
}

.nav-collapsed-min .nav-container .ui-wave {
  overflow: visible;
}

.nav-horizontal.nav-container .ui-wave {
  overflow: visible;
}

.header-container.bg-white .ui-wave .ink {
  background: rgba(0, 0, 0, 0.2);
}

.content-container.ng-leave {
  z-index: 9999;
}

.content-container.ng-enter {
  z-index: 8888;
}

/*==========  animations  ==========*/
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 3s linear infinite;
}

@keyframes fade-up-enter {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-up-leave {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.animate-fade-up.ng-enter {
  animation: 0.35s fade-up-enter;
}

@keyframes animate-flip-y-enter {
  0% {
    transform: perspective(3000px) rotateY(90deg);
    opacity: 0;
  }
  100% {
    transform: perspective(3000px) rotateY(0deg);
    opacity: 1;
  }
}

@keyframes animate-flip-y-leave {
  0% {
    transform: perspective(3000px) rotateY(0deg);
    opacity: 1;
  }
  100% {
    transform: perspective(3000px) rotateY(-90deg);
    opacity: 0;
  }
}

.animate-flip-y.ng-enter {
  animation: 0.35s animate-flip-y-enter ease-in-out;
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOutUp {
  0% {
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

.animate-vertical-slide.ng-hide-add {
  animation: .35s slideOutUp ease-in-out;
}

.animate-vertical-slide.ng-hide-remove {
  animation: .35s .35s slideInDown ease-in-out;
}

/*==========  simple ones  ==========*/
@keyframes slideOutUp {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ainmate-scale-up.ng-enter {
  animation: 0.35s slideOutUp ease-in-out;
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(25px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.ainmate-slide-in-right.ng-enter {
  animation: 0.35s slideInRight ease-in-out;
}

.animated {
  animation-duration: .35s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

@keyframes slideInUp {
  0% {
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes scaleInLeft {
  0% {
    transform-origin: left top;
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.scaleInLeft {
  animation-name: scaleInLeft;
}

@keyframes scaleInRight {
  0% {
    transform-origin: right top;
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.scaleInRight {
  animation-name: scaleInRight;
}

@keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}

.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  100% {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}

.rotateInUpRight {
  animation-name: rotateInUpRight;
}

.dropdown-menu {
  transform-origin: top left;
}

.dropdown-menu.pull-right {
  transform-origin: top right;
}

.dropdown-menu-scaleIn {
  display: block;
  opacity: 0;
  transition: all .35s;
  transform: scale(0);
  backface-visibility: hidden;
}

.open > .dropdown-menu-scaleIn {
  opacity: 1;
  transform: scale(1);
}

.badge-primary {
  background-color: #9C1919;
}

.badge-success {
  background-color: #8BC34A;
}

.badge-info {
  background-color: #00BCD4;
}

.badge-warning {
  background-color: #FFCA28;
}

.badge-danger {
  background-color: #F44336;
}

.box {
  position: relative;
  margin-bottom: 20px;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
}

.box.box-transparent {
  box-shadow: none;
}

.box .box-dark {
  background-color: rgba(0, 0, 0, 0.03);
}

.box-default {
  background-color: #fff;
}

.box-header {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-radius: 1px 1px 0 0;
  font-weight: 500;
}

.box-toolbar {
  position: absolute;
  top: 15px;
  right: 15px;
}

.box-toolbar a {
  color: #777777;
}

.box-toolbar a .material-icons {
  font-size: 14px;
}

.box-divider {
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  margin: 0 15px;
}

.box-body {
  padding: 15px;
}

.box-body:before, .box-body:after {
  content: " ";
  display: table;
}

.box-body:after {
  clear: both;
}

.box-body.padding-lg {
  padding: 30px 15px;
}

.box-body.padding-xl {
  padding: 60px 15px;
}

.box-body.padding-lg-h {
  padding: 15px 30px;
}

.box-body.padding-lg-v {
  padding: 30px 15px;
}

.breadcrumb a {
  color: #3D4051;
}

.breadcrumb a:hover {
  text-decoration: none;
}

.breadcrumb-alt {
  overflow: hidden;
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
  width: 100%;
}

.breadcrumb-alt > li {
  float: left;
  margin: 0 25px 0 0;
}

.breadcrumb-alt > li > a {
  position: relative;
  float: left;
  background-color: #eeeeee;
  color: #3D4051;
  font-size: 12px;
  padding: 10px;
}

.breadcrumb-alt > li > a:before {
  position: absolute;
  top: 50%;
  left: -1em;
  margin-top: -1.6em;
  border-color: #eeeeee #eeeeee #eeeeee rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 1.5em 0 1.7em 1em;
  content: "";
}

.breadcrumb-alt > li > a:after {
  position: absolute;
  top: 50%;
  right: -1em;
  margin-top: -1.5em;
  border-bottom: 1.5em solid rgba(0, 0, 0, 0);
  border-left: 1em solid #eeeeee;
  border-top: 1.5em solid rgba(0, 0, 0, 0);
  content: "";
}

.breadcrumb-alt > li > a:hover, .breadcrumb-alt > li > a.active {
  text-decoration: none;
  background-color: #9C1919;
  color: #fff;
}

.breadcrumb-alt > li > a:hover:before, .breadcrumb-alt > li > a.active:before {
  border-color: #9C1919 #9C1919 #9C1919 rgba(0, 0, 0, 0);
}

.breadcrumb-alt > li > a:hover:after, .breadcrumb-alt > li > a.active:after {
  border-left: 1em solid #9C1919;
}

.callout {
  margin: 20px 0;
  padding: 20px;
  border-left: 3px solid #eee;
}

.callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.callout p:last-child {
  margin-bottom: 0;
}

.callout-success {
  background-color: #f3faf3;
  border-color: #8BC34A;
}

.callout-success h4 {
  color: #8BC34A;
}

.callout-info {
  background-color: #f4f8fa;
  border-color: #00BCD4;
}

.callout-info h4 {
  color: #00BCD4;
}

.callout-warning {
  background-color: #fcf8f2;
  border-color: #FFCA28;
}

.callout-warning h4 {
  color: #FFCA28;
}

.callout-danger {
  background-color: #fdf7f7;
  border-color: #F44336;
}

.callout-danger h4 {
  color: #F44336;
}

.dropdown-menu.dropdown-dark {
  background-color: #262B33;
}

.dropdown-menu.dropdown-dark.with-arrow:after {
  border-bottom: 7px solid #262B33;
}

.dropdown-menu.dropdown-dark > li > a {
  color: #777777;
}

.dropdown-menu.with-arrow {
  margin-top: 0;
}

.dropdown-menu.with-arrow:before {
  content: " ";
  position: absolute;
  left: 12px;
  top: -16px;
  border: 8px solid transparent;
  border-bottom: 8px solid rgba(0, 0, 0, 0.15);
}

.dropdown-menu.with-arrow:after {
  content: " ";
  position: absolute;
  left: 13px;
  top: -14px;
  border: 7px solid transparent;
  border-bottom: 7px solid #fff;
}

.dropdown-menu.with-arrow.pull-right {
  margin-top: 0;
}

.dropdown-menu.with-arrow.pull-right:before {
  left: auto;
  right: 12px;
}

.dropdown-menu.with-arrow.pull-right:after {
  left: auto;
  right: 13px;
}

.dropdown-menu.with-arrow.panel {
  border: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.dropdown-menu.with-arrow.panel-default:before {
  border-bottom: 8px solid #e2edeb;
}

.dropdown-menu.with-arrow.panel-default:after {
  border-bottom: 7px solid #F2F7F7;
}

.dropdown-menu.with-arrow.panel-dark:before {
  border-bottom: 8px solid #262B33;
}

.dropdown-menu.with-arrow.panel-dark:after {
  border-bottom: 7px solid #262B33;
}

.dropdown-menu.with-arrow.panel-primary:before {
  border-bottom: 8px solid #9C1919;
}

.dropdown-menu.with-arrow.panel-primary:after {
  border-bottom: 7px solid #9C1919;
}

.dropdown-menu.with-arrow.panel-success:before {
  border-bottom: 8px solid #ebf8cd;
}

.dropdown-menu.with-arrow.panel-success:after {
  border-bottom: 7px solid #F0FBE3;
}

.dropdown-menu.with-arrow.panel-info:before {
  border-bottom: 8px solid #c5f1fa;
}

.dropdown-menu.with-arrow.panel-info:after {
  border-bottom: 7px solid #E6F5FD;
}

.dropdown-menu.with-arrow.panel-warning:before {
  border-bottom: 8px solid #ffecd4;
}

.dropdown-menu.with-arrow.panel-warning:after {
  border-bottom: 7px solid #FFFAED;
}

.dropdown-menu.with-arrow.panel-danger:before {
  border-bottom: 8px solid #f7d0d1;
}

.dropdown-menu.with-arrow.panel-danger:after {
  border-bottom: 7px solid #FBE9E6;
}

.ih-item {
  position: relative;
  border-radius: 2px;
  transition: all 0.35s ease-in-out;
}

.ih-item a {
  color: #333;
}

.ih-item a:hover {
  text-decoration: none;
}

.ih-item img {
  border-radius: 2px;
  width: 100%;
  height: 100%;
}

.ih-item.ih-material {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.ih-item.ih-material .info-mask {
  content: '';
  position: absolute;
  top: -25%;
  left: 0;
  right: 0;
  padding-bottom: 100%;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.ih-item.ih-material .info-content {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backface-visibility: hidden;
  transform: scale(0.5);
  transition: all .3s ease;
}

.ih-item.ih-material .info-content:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  margin-left: -0.5em;
}

.ih-item.ih-material .info-content .info-inner {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  text-align: center;
}

.ih-item.ih-material .info-content .info-inner h3 {
  margin: 0 0 10px;
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
}

.ih-item.ih-material .info-content .info-inner p {
  font-style: italic;
  font-size: 12px;
  color: #eeeeee;
}

.ih-item.ih-material .bg-white + .info-content .info-inner h3,
.ih-item.ih-material .bg-warning + .info-content .info-inner h3 {
  color: #3D4051;
}

.ih-item.ih-material .bg-white + .info-content .info-inner p,
.ih-item.ih-material .bg-warning + .info-content .info-inner p {
  color: #999;
}

.ih-item.ih-material a:hover .info-content {
  opacity: 1;
  transform: scale(1);
}

.ih-item.ih-material a:hover .info-mask {
  opacity: .8;
  transform: scale(1.21);
}

.form-validation md-input-container > md-icon {
  color: #999;
}

.form-validation md-input-container:not(.md-input-invalid).md-input-has-value > md-icon {
  color: #8BC34A;
}

.form-validation md-input-container.md-input-invalid > md-icon {
  color: #F44336;
}

.validation-messages {
  font-size: 12px;
  margin: 10px 0 0 20px;
  color: #F44336;
}

img.img30_30 {
  width: 30px;
  height: 30px;
}

img.img40_40 {
  width: 40px;
  height: 40px;
}

img.img64_64 {
  width: 64px;
  height: 64px;
}

img.img80_80 {
  width: 80px;
  height: 80px;
}

.label {
  padding: .5em .8em;
}

.label-info-alt {
  background: #7E57C2;
}

.list-group-item {
  padding: 15px;
  border: 1px solid #f3f3f3;
}

.list-info li {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.list-info li:last-child {
  border-bottom: none;
}

.list-info li .icon {
  margin-right: 10px;
  color: #9C1919;
}

.list-info li label {
  width: 100px;
}

.media-list.media-divider-full .media {
  border-bottom: 1px solid #e2edeb;
}

.media-list.media-divider-inset .media-body {
  border-bottom: 1px solid #e2edeb;
}

.nav-boxed {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 2px;
}

.nav-boxed.nav-justified > li > a {
  margin: 0;
}

.nav-boxed a {
  color: #3D4051;
}

.nav-boxed > li > a {
  display: block;
  padding: 10px 5px;
  border-left: 0;
  border-top: 1px solid #f3f3f3;
}

@media (min-width: 768px) {
  .nav-boxed > li > a {
    border-top: 0;
    border-left: 1px solid #f3f3f3;
  }
}

.nav-boxed > li > a > i {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto 2px;
  font-size: 30px;
}

.nav-boxed > li:first-child > a {
  border-left: none;
  border-top: 0;
}

@media (min-width: 768px) {
  .nav-boxed > li:first-child > a {
    border-left: 1px solid #f3f3f3;
  }
}

.panel {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.panel .panel {
  box-shadow: none;
}

.panel .panel-heading {
  text-transform: uppercase;
}

.panel .panel-title {
  font-size: 14px;
}

.panel.panel-labeled {
  position: relative;
}

.panel.panel-labeled .panel-body {
  padding: 35px 15px;
}

.panel.panel-minimal .panel-heading {
  background: none;
  border: 0;
}

.panel .panel-label {
  color: #ccc;
  position: absolute;
  bottom: 5px;
  left: 7px;
}

.panel .panel-body.padding-lg {
  padding: 30px 15px;
}

.panel .panel-body.padding-xl {
  padding: 60px 15px;
}

.panel .panel-body.padding-lg-h {
  padding: 15px 30px;
}

.panel-dark {
  border-color: #262B33;
}

.panel-dark > .panel-heading {
  color: #fff;
  background-color: #262B33;
  border-color: #262B33;
}

.panel-dark > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #262B33;
}

.panel-dark > .panel-heading .badge {
  color: #262B33;
  background-color: #fff;
}

.panel-dark > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #262B33;
}

.panel-box {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  border: none;
}

.panel-box .panel-item {
  display: table-cell;
  padding: 30px;
  width: 1%;
  vertical-align: top;
  border-radius: 0;
}

.panel-box .panel-left {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.panel-box .panel-right {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

.panel-box.info-box i {
  line-height: 70px;
}

.panel-box .panel-top,
.panel-box .panel-bottom {
  display: block;
}

.panel-box .panel-top {
  padding: 30px 20px;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.panel-box .panel-bottom {
  padding: 10px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.panel-box .panel-bottom p {
  margin: 0;
}

.panel-box .list-justified-container {
  padding: 15px 0;
}

.panel-box ul.list-justified {
  display: table;
  width: 100%;
  list-style: none;
  padding: 0;
}

.panel-box ul.list-justified > li {
  float: none;
  display: table-cell;
  padding: 10px;
  width: 1%;
  border-right: 1px solid #eeeeee;
}

.panel-box ul.list-justified > li:last-child {
  border: none;
}

.panel-box ul.list-justified > li p {
  margin: 0;
}

.panel-box .panel-icon,
.panel-box .panel-img {
  display: block;
  margin: -75px auto 0;
  border-radius: 50%;
  border: 10px solid #ffffff;
  width: 150px;
  height: 150px;
  line-height: 80px;
  text-align: center;
  font-size: 58px;
  text-shadow: -6px 8px 5px rgba(0, 0, 0, 0.3);
}

.panel-box .panel-icon {
  padding: 28px 35px 35px;
}

.panel-box .panel-img {
  padding: 0;
}

.panel-box .panel-img img {
  width: 100%;
  max-width: 100%;
}

.mini-box {
  min-height: 120px;
  padding: 25px;
}

.mini-box .box-icon {
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  vertical-align: middle;
  text-align: center;
  font-size: 35px;
  border-radius: 2px;
}

.mini-box .box-icon.rounded {
  border-radius: 50%;
}

.mini-box .btn-icon,
.mini-box .btn-icon-lined {
  margin: 0 15px 0 0;
  font-size: 32px;
}

.mini-box .box-info {
  display: inline-block;
  vertical-align: top;
}

.mini-box .box-info p {
  margin: 0;
}

.panel-profile {
  border: none;
}

.panel-profile .profile {
  margin: 5px 15px 5px 5px;
  border-radius: 50%;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.panel-profile .profile img {
  border-radius: 50%;
}

.panel-profile .list-group > li > i {
  margin-right: 10px;
  font-size: 16px;
  color: #777777;
  table-layout: fixed;
}

.panel-profile .list-justified-container {
  padding: 15px 0;
}

.panel-profile ul.list-justified {
  display: table;
  width: 100%;
  list-style: none;
  padding: 0;
}

.panel-profile ul.list-justified > li {
  float: none;
  display: table-cell;
  padding: 10px;
  width: 1%;
  border-right: 1px solid #eeeeee;
}

.panel-profile ul.list-justified > li:last-child {
  border: none;
}

.panel-profile ul.list-justified > li p {
  margin: 0;
}

.popover-title {
  padding: 8px 14px 0;
  color: #aaa;
  font-weight: bold;
  border-bottom: none;
}

.popover-content {
  color: #fafafa;
}

.pricing-table {
  position: relative;
  margin-bottom: 20px;
  background-color: #fcfcfc;
  color: #999;
  border-radius: 2px;
  box-shadow: none;
  text-align: center;
  transition: .25s ease-out;
}

.pricing-table header {
  text-align: center;
  padding: 12px 8px;
  border-radius: 2px 2px 0 0;
}

.pricing-table header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  text-transform: uppercase;
}

.pricing-table .pricing-price {
  border-bottom: 1px solid #e7edf6;
  padding: 45px 15px;
  background-color: #A0A2AA;
  font-size: 56px;
  line-height: 1;
  color: #fff;
  font-weight: 300;
}

.pricing-table .pricing-price .pricing-sign {
  margin-right: 5px;
  font-size: 24px;
  font-weight: normal;
}

.pricing-table .pricing-price .pricing-sub {
  margin-left: 5px;
  font-size: 22px;
  line-height: 1;
}

.pricing-table .pricing-lead {
  margin-bottom: 1.5em;
  font-size: 18px;
  text-transform: uppercase;
}

.pricing-table .pricing-lead:after {
  margin: 0 auto;
  content: "";
  height: 0px;
  width: 80px;
  padding-top: .6em;
  border-bottom: solid 3px #eff0f3;
  display: block;
}

.pricing-table .pricing-plan-details {
  padding: 1em;
}

.pricing-table .pricing-plan-details > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
  line-height: 2em;
}

.pricing-table > footer {
  padding: 2em;
  text-align: center;
}

.pricing-table > footer > a {
  display: block;
  margin: 0 auto;
  padding: 12px;
  font-size: 14px;
  line-height: 1;
  width: 100%;
  max-width: 200px;
}

.pricing-table.pricing-table-primary .pricing-price {
  background-color: #9C1919;
}

.pricing-table.pricing-table-primary:after {
  border-top: 6px solid #9C1919;
  border-left: 6px solid #9C1919;
}

.pricing-table.pricing-table-secondary .pricing-price, .pricing-table.pricing-table-info .pricing-price {
  background-color: #00BCD4;
}

.pricing-table.pricing-table-secondary:after, .pricing-table.pricing-table-info:after {
  border-top: 6px solid #00BCD4;
  border-left: 6px solid #00BCD4;
}

.pricing-table.pricing-table-success .pricing-price {
  background-color: #8BC34A;
}

.pricing-table.pricing-table-success:after {
  border-top: 6px solid #8BC34A;
  border-left: 6px solid #8BC34A;
}

.pricing-table.pricing-table-warning .pricing-price {
  background-color: #FFCA28;
}

.pricing-table.pricing-table-warning:after {
  border-top: 6px solid #FFCA28;
  border-left: 6px solid #FFCA28;
}

.pricing-table.pricing-table-danger .pricing-price {
  background-color: #F44336;
}

.pricing-table.pricing-table-danger:after {
  border-top: 6px solid #F44336;
  border-left: 6px solid #F44336;
}

.ui-ribbon-container {
  position: relative;
}

.ui-ribbon-container .ui-ribbon-wrapper {
  position: absolute;
  overflow: hidden;
  width: 85px;
  height: 88px;
  top: -3px;
  right: -3px;
}

.ui-ribbon-container .ui-ribbon {
  position: relative;
  display: block;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  transform: rotate(45deg);
  padding: 7px 0;
  left: -5px;
  top: 15px;
  width: 120px;
  line-height: 20px;
  background-color: #555555;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.ui-ribbon-container .ui-ribbon:before, .ui-ribbon-container .ui-ribbon:after {
  position: absolute;
  content: " ";
  line-height: 0;
  border-top: 2px solid #555555;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  bottom: -2px;
}

.ui-ribbon-container .ui-ribbon:before {
  left: 0;
  bottom: -1px;
}

.ui-ribbon-container .ui-ribbon:after {
  right: 0;
}

.ui-ribbon-container.ui-ribbon-primary .ui-ribbon {
  background-color: #9C1919;
}

.ui-ribbon-container.ui-ribbon-primary .ui-ribbon:before, .ui-ribbon-container.ui-ribbon-primary .ui-ribbon:after {
  border-top: 2px solid #9C1919;
}

.ui-ribbon-container.ui-ribbon-success .ui-ribbon {
  background-color: #8BC34A;
}

.ui-ribbon-container.ui-ribbon-success .ui-ribbon:before, .ui-ribbon-container.ui-ribbon-success .ui-ribbon:after {
  border-top: 2px solid #8BC34A;
}

.ui-ribbon-container.ui-ribbon-info .ui-ribbon {
  background-color: #00BCD4;
}

.ui-ribbon-container.ui-ribbon-info .ui-ribbon:before, .ui-ribbon-container.ui-ribbon-info .ui-ribbon:after {
  border-top: 2px solid #00BCD4;
}

.ui-ribbon-container.ui-ribbon-warning .ui-ribbon {
  background-color: #FFCA28;
}

.ui-ribbon-container.ui-ribbon-warning .ui-ribbon:before, .ui-ribbon-container.ui-ribbon-warning .ui-ribbon:after {
  border-top: 2px solid #FFCA28;
}

.ui-ribbon-container.ui-ribbon-danger .ui-ribbon {
  background-color: #F44336;
}

.ui-ribbon-container.ui-ribbon-danger .ui-ribbon:before, .ui-ribbon-container.ui-ribbon-danger .ui-ribbon:after {
  border-top: 2px solid #F44336;
}

.flags-sprite,
.flags-english,
.flags-chinese,
.flags-french,
.flags-german,
.flags-italian,
.flags-japanese,
.flags-korea,
.flags-portuguese,
.flags-russian,
.flags-spanish {
  background-image: url("ui/images/flags.png");
  background-repeat: no-repeat;
}

.flags-english {
  background-position: 0 0;
  height: 32px;
  width: 32px;
}

.flags-chinese {
  background-position: 0 -32px;
  height: 32px;
  width: 32px;
}

.flags-french {
  background-position: 0 -64px;
  height: 32px;
  width: 32px;
}

.flags-german {
  background-position: 0 -96px;
  height: 32px;
  width: 32px;
}

.flags-italian {
  background-position: 0 -128px;
  height: 32px;
  width: 32px;
}

.flags-japanese {
  background-position: 0 -160px;
  height: 32px;
  width: 32px;
}

.flags-korea {
  background-position: 0 -192px;
  height: 32px;
  width: 32px;
}

.flags-portuguese {
  background-position: 0 -224px;
  height: 32px;
  width: 32px;
}

.flags-russian {
  background-position: 0 -256px;
  height: 32px;
  width: 32px;
}

.flags-spanish {
  background-position: 0 -288px;
  height: 32px;
  width: 32px;
}

.ui-timline-container {
  padding: 15px;
}

.ui-timline-left .ui-timeline:before {
  left: 0;
}

@media (min-width: 768px) {
  .ui-timline-left .ui-timeline .tl-item:before {
    display: none;
  }
}

@media (min-width: 768px) {
  .ui-timline-left .ui-timeline .tl-item .tl-caption {
    margin-left: -55px;
  }
}

@media (min-width: 768px) {
  .ui-timline-left .ui-timeline .tl-item .tl-body .tl-time {
    left: auto;
    right: 15px;
    color: #777777;
  }
}

.ui-timeline {
  display: table;
  position: relative;
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.ui-timeline:before {
  background-color: #d5d5d5;
  bottom: 0px;
  content: "";
  position: absolute;
  left: 0;
  top: 30px;
  width: 1px;
  z-index: 0;
}

@media (min-width: 768px) {
  .ui-timeline:before {
    left: 50%;
  }
}

.ui-timeline .tl-item {
  display: table-row;
  margin-bottom: 5px;
}

.ui-timeline .tl-item:before {
  display: none;
  content: "";
}

@media (min-width: 768px) {
  .ui-timeline .tl-item:before {
    display: block;
    width: 50%;
  }
}

.ui-timeline .tl-item .tl-caption {
  width: 150px;
  margin-left: -55px;
}

@media (min-width: 768px) {
  .ui-timeline .tl-item .tl-caption {
    margin-left: -110px;
  }
}

@media (min-width: 768px) {
  .ui-timeline .tl-item.alt {
    text-align: right;
  }
  .ui-timeline .tl-item.alt:before {
    display: none;
  }
  .ui-timeline .tl-item.alt:after {
    content: "";
    display: block;
    width: 50%;
  }
  .ui-timeline .tl-item.alt .tl-body .tl-entry {
    margin: 0 35px 15px 0;
  }
  .ui-timeline .tl-item.alt .tl-body .tl-time {
    right: -220px;
    left: auto;
    text-align: left;
  }
  .ui-timeline .tl-item.alt .tl-body .tl-icon {
    right: -53px;
    left: auto;
  }
  .ui-timeline .tl-item.alt .tl-body .tl-content:after {
    right: -16px;
    left: auto;
    border: 8px solid transparent;
    border-left: 8px solid rgba(255, 255, 255, 0.9);
    border-top: 8px solid rgba(255, 255, 255, 0.9);
  }
}

.ui-timeline .tl-item .tl-body {
  display: table-cell;
  width: 50%;
  vertical-align: top;
}

.ui-timeline .tl-item .tl-body .tl-entry {
  position: relative;
  margin: 0 0 15px 36px;
}

.ui-timeline .tl-item .tl-body .tl-time {
  z-index: 1;
  position: absolute;
  left: auto;
  right: 15px;
  top: 0;
  width: 150px;
  color: #777777;
  line-height: 35px;
  text-align: right;
}

@media (min-width: 768px) {
  .ui-timeline .tl-item .tl-body .tl-time {
    left: -220px;
    right: auto;
    color: #3D4051;
  }
}

.ui-timeline .tl-item .tl-body .tl-icon {
  position: absolute;
  left: -53px;
  top: 0;
}

.ui-timeline .tl-item .tl-body .tl-content {
  position: relative;
  padding: 15px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.9);
}

.ui-timeline .tl-item .tl-body .tl-content:after {
  content: " ";
  line-height: 0;
  position: absolute;
  left: -16px;
  top: 0;
  border: 8px solid transparent;
  border-right: 8px solid rgba(255, 255, 255, 0.9);
  border-top: 8px solid rgba(255, 255, 255, 0.9);
}

.tooltip-inner {
  padding: .85em;
}

.mdl-shadow--2dp, .card-panel, .card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-shadow--3dp {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.mdl-shadow--4dp {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--6dp {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--8dp {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--16dp {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--24dp {
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
}

.md-button {
  margin: 0;
  line-height: 1.42857;
}

.md-button.md-fab {
  font-size: 20px;
  padding: 0;
}

.md-button.md-fab.md-fab-square {
  border-radius: 2px;
}

.md-button.md-fab.md-fab-xs {
  font-size: 14px;
  padding: 0;
  width: 36px;
  height: 36px;
  line-height: 36px;
}

.md-button.md-fab.md-fab-sm {
  font-size: 14px;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.md-button.md-fab.md-fab-lg {
  font-size: 28px;
  width: 65px;
  height: 65px;
  line-height: 65px;
}

.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 2px;
}

.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
}

.card-panel {
  padding: 20px;
  margin: 10px 0 20px 0;
  border-radius: 2px;
}

.card {
  position: relative;
  overflow: hidden;
  margin: 10px 0 20px 0;
  border-radius: 2px;
}

.card .card-title {
  font-size: 24px;
}

.card .card-title.activator {
  cursor: pointer;
}

.card .card-action > a {
  margin-right: 20px;
  text-transform: uppercase;
}

.card .card-image {
  position: relative;
}

.card .card-image img {
  border-radius: 2px 2px 0 0;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.card .card-image .card-title {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
}

.card .card-content {
  padding: 20px;
  border-radius: 0 0 2px 2px;
}

.card .card-content p {
  margin: 0;
  color: inherit;
}

.card .card-content .card-title {
  line-height: 48px;
}

.card .card-content .card-profile-img {
  position: relative;
  z-index: 1;
  margin-top: -70px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 5px;
  background-color: #fff;
}

.card .card-content .card-profile-img img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.card .card-content .card-profile-img.profile-img-sm {
  margin-top: -50px;
  width: 60px;
  height: 60px;
  padding: 2px;
}

.card .card-content .card-profile-img.profile-img-sm img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.card .card-content .card-button .btn-icon {
  position: relative;
  z-index: 1;
  margin-top: -65px;
}

.card .card-action {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 20px;
}

.card .card-action.no-border {
  border: 0;
}

.card .card-reveal {
  padding: 20px;
  position: absolute;
  background-color: #FFF;
  width: 100%;
  overflow-y: auto;
  top: 100%;
  height: 100%;
  z-index: 1;
}

.card .card-reveal .card-title {
  cursor: pointer;
  display: block;
}

.ui-input-group {
  position: relative;
  margin: 18px 0 12px;
}

.ui-input-group .form-control:focus {
  border-color: #9C1919;
}

.ui-input-group > input,
.ui-input-group > textarea {
  padding-left: 0;
  padding-right: 0;
  border: none;
  border-bottom: 1px solid #CBD5DD;
}

.ui-input-group > input:focus,
.ui-input-group > textarea:focus {
  outline: none;
}

.ui-input-group > input:focus ~ label,
.ui-input-group > input:valid ~ label,
.ui-input-group > textarea:focus ~ label,
.ui-input-group > textarea:valid ~ label {
  top: -20px;
  left: 0;
  font-size: 12px;
}

.ui-input-group > input:valid ~ label,
.ui-input-group > textarea:valid ~ label {
  color: #999;
}

.ui-input-group > input:focus ~ label,
.ui-input-group > textarea:focus ~ label {
  color: #9C1919;
}

.ui-input-group > input:focus ~ .input-bar:before,
.ui-input-group > input:focus ~ .input-bar:after,
.ui-input-group > textarea:focus ~ .input-bar:before,
.ui-input-group > textarea:focus ~ .input-bar:after {
  width: 50%;
}

.ui-input-group > label {
  color: #999;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 10px;
  transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.ui-input-group .input-bar {
  position: relative;
  display: block;
  width: 100%;
}

.ui-input-group .input-bar:before,
.ui-input-group .input-bar:after {
  content: '';
  height: 1px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #9C1919;
  transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.ui-input-group .input-bar:before {
  left: 50%;
}

.ui-input-group .input-bar:after {
  right: 50%;
}

.ui-accordion .panel-group .panel-heading + .panel-collapse .panel-body {
  border-top: 1px solid #e2edeb;
}

.ui-accordion .panel {
  border: 1px solid #e2edeb;
}

.ui-accordion .panel-heading {
  padding: 0;
  background-color: #F2F7F7;
}

.ui-accordion .panel-heading .panel-title > a {
  display: block;
  padding: 15px;
  font-size: 14px;
}

.ui-accordion .panel-heading .panel-title > a:hover, .ui-accordion .panel-heading .panel-title > a:active, .ui-accordion .panel-heading .panel-title > a:focus {
  cursor: pointer;
  text-decoration: none;
}

.ui-accordion-success .panel {
  border: 1px solid #8BC34A;
}

.ui-accordion-success .panel-heading {
  background-color: #8BC34A;
  color: #ffffff;
}

.ui-accordion-info .panel {
  border: 1px solid #00BCD4;
}

.ui-accordion-info .panel-heading {
  background-color: #00BCD4;
  color: #ffffff;
}

.ui-accordion-warning .panel {
  border: 1px solid #FFCA28;
}

.ui-accordion-warning .panel-heading {
  background-color: #FFCA28;
  color: #ffffff;
}

.ui-accordion-danger .panel {
  border: 1px solid #F44336;
}

.ui-accordion-danger .panel-heading {
  background-color: #F44336;
  color: #ffffff;
}

.ui-accordion-dark .panel {
  border: 1px solid #262B33;
}

.ui-accordion-dark .panel-heading {
  background-color: #262B33;
  color: #ffffff;
}

.ui-map {
  display: block;
  height: 400px;
}

.pagination-lg > li > a,
.pagination-lg > li span {
  line-height: 26px;
  padding: 10px 18px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span, .pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 2px;
}

.pagination > li > a {
  color: #3D4051;
  border-radius: 2px;
  cursor: pointer;
}

.pagination > li > a,
.pagination > li > span {
  padding: 5px 10px;
  margin-left: 10px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 20px;
}

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

.ui-tab .nav-tabs {
  margin-bottom: 15px;
}

.ui-tab .nav-tabs.nav-justified > li.active > a {
  border-bottom-color: transparent;
}

.ui-tab .nav-tabs > li.active > a {
  background-color: #ffffff;
}

.ui-tab .nav-tabs a {
  color: #3D4051;
}

.ui-tab .nav-tabs a:hover {
  cursor: pointer;
}

.ui-tab-container .nav-tabs {
  margin-bottom: 0;
}

.ui-tab-container .nav-tabs.nav-justified > li:last-child {
  border-right: 0;
}

.ui-tab-container .nav-tabs.nav-justified > li:hover > a, .ui-tab-container .nav-tabs.nav-justified > li:active > a, .ui-tab-container .nav-tabs.nav-justified > li:focus > a {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #ddd;
}

.ui-tab-container .nav-tabs.nav-justified > li > a {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #ddd;
}

.ui-tab-container .nav-tabs > li.active > a {
  color: #9C1919;
}

.ui-tab-container .nav-tabs > li:hover > a, .ui-tab-container .nav-tabs > li:active > a, .ui-tab-container .nav-tabs > li:focus > a {
  border: 0;
  background-color: transparent;
}

.ui-tab-container .nav-tabs > li > a {
  margin: 0;
  border: 0;
  padding: 15px 15px;
  font-weight: bold;
  border-radius: 0;
}

.ui-tab-container .tab-content {
  padding: 15px 15px;
}

.ui-tab-horizontal {
  border: 1px solid #ddd;
  border-radius: 2px;
}

.ui-tab-horizontal .nav-tabs {
  background-color: #F2F7F7;
}

.ui-tab-horizontal .nav-tabs > li {
  border-right: 1px solid #ddd;
}

.ui-tab-horizontal .nav-tabs > li.active:hover > a, .ui-tab-horizontal .nav-tabs > li.active:active > a, .ui-tab-horizontal .nav-tabs > li.active:focus > a {
  border: 0;
  background-color: #fff;
}

.ui-tab-horizontal .nav-tabs > li.active > a:focus, .ui-tab-horizontal .nav-tabs > li.active > a:hover, .ui-tab-horizontal .nav-tabs > li.active > a:active {
  border: 0;
}

.ui-tab-vertical {
  position: relative;
  padding: 0 0 0 30%;
}

.ui-tab-vertical .nav-tabs {
  position: absolute;
  left: 0;
  top: 0;
  width: 30%;
  background-color: #F2F7F7;
  border-width: 1px 0 1px 1px;
  border-radius: 2px 0 0 2px;
  border-color: #ddd;
  border-style: solid;
}

.ui-tab-vertical .nav-tabs > li {
  width: 100%;
  float: none;
  border-bottom: 1px solid #ddd;
  margin: 0;
}

.ui-tab-vertical .nav-tabs > li:last-child {
  border-bottom: 0;
}

.ui-tab-vertical .nav-tabs > li.active:hover > a, .ui-tab-vertical .nav-tabs > li.active:active > a, .ui-tab-vertical .nav-tabs > li.active:focus > a {
  border: 0;
  background-color: #fff;
}

.ui-tab-vertical .nav-tabs > li.active > a {
  margin-right: -1px;
}

.ui-tab-vertical .nav-tabs > li.active > a:focus, .ui-tab-vertical .nav-tabs > li.active > a:hover, .ui-tab-vertical .nav-tabs > li.active > a:active {
  border: 0;
}

.ui-tab-vertical .nav-tabs > li > a {
  display: block;
}

.ui-tab-vertical .tab-content {
  border: 1px solid #ddd;
  border-radius: 0 2px 2px 0;
}
